const menuList = [
  {
    menuId: 1,
    title: "ข้อมูลประปาหมู่บ้าน",
    titleCollapse: "ประปาหมู่บ้าน",
    iconClass: "ic:round-water-drop",
    to: "WaterSupply",
    auth: [""],
    active: false,
  },
  {
    menuId: 2,
    title: "ข้อมูลประปาถ่ายโอน",
    titleCollapse: "ประปาถ่ายโอน",
    iconClass: "fluent:table-move-right-24-filled",
    to: "TransferSupply",
    auth: [""],
    active: false,
  },
  {
    menuId: 3,
    title: "จัดการผู้ใช้งาน",
    titleCollapse: "ผู้ใช้งาน",
    iconClass: "bx:bxs-user",
    to: "UserManagement",
    auth: [""],
    active: false,
  },
];
const breadcrumbs = [
  {
    title: "ข้อมูลประปาหมู่บ้าน",
    to: "WaterSupply",
    iconClass: "ic:round-water-drop",
    auth: [""],
    children: [
      {
        title: "เพิ่มข้อมูลประปาหมู่บ้าน",
        to: "AddWaterSupply",
        auth: [""],
      },
    ],
  },
  {
    title: "ข้อมูลประปาถ่ายโอน",
    to: "TransferSupply",
    iconClass: "fluent:table-move-right-24-filled",
    auth: [""],
    children: [],
  },
  {
    title: "จัดการผู้ใช้งาน",
    to: "UserManagement",
    iconClass: "bx:bxs-user",
    auth: [""],
    children: [
      {
        title: "เพิ่มผู้ใช้งาน",
        to: "AddUser",
        auth: [""],
      },
      {
        title: "แก้ไขผู้ใช้งาน",
        to: "EditUser",
        auth: [""],
      },
    ],
  },
];

const state = {
  menus: [...menuList],
  breadcrumbs: [...breadcrumbs],
};

export const menus = {
  namespaced: true,
  state,
};
