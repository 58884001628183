import axios from "axios";
import router from "../router/index";
import store from "../store/index";

import { TokenService, TOKEN_STRING } from "./storage.service";
import {
  interceptorRequest,
  interceptorRequestError,
} from "./interceptors/request";
import { interceptorResponse } from "./interceptors/response";

const headers = {};

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  headers: headers,
});

let resInterceptor;

const createAxiosResponseInterceptor = () => {
  httpClient.interceptors.response.handlers = [];
  resInterceptor = httpClient.interceptors.response.use(
    interceptorResponse,
    interceptorResponseError
  );
};

const interceptorResponseError = async (error) => {
  console.log("resInterceptor:ERROR", error);

  if (error.response === undefined) {
    console.log("error.response", error.response);
    return Promise.reject(error);
  }

  if (error.response && error.response.status !== 401) {
    console.log("error.response.status", error.response.status);

    return Promise.reject(error);
  }

  httpClient.interceptors.response.eject(resInterceptor);

  const refreshToken =
    store.state.account && store.state.account.user
      ? store.state.account.user.refreshToken
      : null;
  const getRefresh = TokenService.getRefreshToken();

  const data = {
    refreshToken: getRefresh || refreshToken,
  };

  const newToken = await axios
    .post(process.env.VUE_APP_BASE_API + "Auth/RefreshToken", data)
    .then(async (response) => {
      console.log("http-response-response", response);

      if (response && response.data && response.status === 200) {
        const { accessToken, refreshToken } = response.data;

        if (accessToken && refreshToken) {
          TokenService.saveToken(accessToken);
          TokenService.saveRefreshToken(refreshToken);

          store.commit("account/loginSuccess", {
            accessToken: accessToken,
            refreshToken: refreshToken,
          });

          error.response.config.headers["Authorization"] =
            TOKEN_STRING + " " + accessToken;
        }
      }

      const _axios = await axios.request(error.response.config);

      const customRes = {
        data: _axios.data.data,
        resStatusCode: _axios.status,
        resStatusText: _axios.statusText,
        resData: _axios.data,
      };

      return customRes;
    })
    .catch((error) => {
      console.log("http-response-error", error);

      TokenService.removeToken();
      TokenService.removeRefreshToken();
      store.commit("account/logoutSuccess");

      router.push({ name: "Login" });

      return Promise.reject(error);
    })
    .finally(createAxiosResponseInterceptor);

  return newToken;
};

httpClient.interceptors.request.use(
  interceptorRequest,
  interceptorRequestError
);

resInterceptor = httpClient.interceptors.response.use(
  interceptorResponse,
  interceptorResponseError
);

export default httpClient;
