<template>
  <div class="map-header">
    <div class="main-header d-flex align-items-center px-3">
      <img
        class="header-logo-img"
        src="/images/logo.png"
        style="height: 50px; margin-right: 5px"
      />
      <div class="flex-fill">
        ระบบสารสนเทศภูมิศาสตร์ระบบประปาหมู่บ้าน กรมทรัพยากรน้ำ
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "map-header",
};
</script>

<style lang="scss"></style>
