<template>
  <div id="AppTitleContent" class="header-breadcrumb">
    <div class="d-flex align-items-center app-title-content">
      <div v-if="items && items.length > 0">
        <iconify :icon="`${items[0].icon}`" class="fontsize-h2" />
      </div>

      <b-breadcrumb>
        <b-breadcrumb-item
          v-for="(item, index) in items"
          :key="item.text"
          :to="item.to"
          :active="item.active"
          :class="{ 'menu-parent': index === 0 }"
        >
          {{ item.text }}
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>
  </div>
</template>

<script>
export default {
  name: "app-title-content",
  data() {
    return {
      breadcrumbs: [],
      currentRouteName: this.$route.name,
      items: [],
    };
  },
  created() {
    window._bc = this;
    this.breadcrumbs = this.$store.state.menus.breadcrumbs;
  },
  mounted() {
    this.setItems();
  },
  methods: {
    displayedCrumbs() {
      const routeBreadcrumbList = this.breadcrumbs;

      const foundBreadcrumbs = this.findInNestedByName(
        routeBreadcrumbList,
        this.currentRouteName
      );

      if (!foundBreadcrumbs.length) {
        console.warn(
          `No breadcrumbs registered for route with name "${this.currentRouteName}"`
        );
      }

      return foundBreadcrumbs;
    },
    findInNestedByName(routeBreadcrumbList, to) {
      for (const routeBreadcrumb of routeBreadcrumbList) {
        if (routeBreadcrumb.to === to) {
          return [routeBreadcrumb];
        }
        if (!routeBreadcrumb.children) {
          continue;
        }

        let result = this.findInNestedByName(routeBreadcrumb.children, to);
        if (result.length) {
          return [routeBreadcrumb, ...result];
        }
      }

      return [];
    },
    setItems() {
      const crumbs = this.displayedCrumbs();

      this.items = crumbs.map((crumb, key) => {
        return {
          text: crumb.title,
          to: !crumb.disabled ? { name: crumb.to } : {},
          active: crumb.disabled || key === crumbs.length - 1,
          icon: crumb.iconClass,
        };
      });
      console.log("item : ", this.items[0].icon);
    },
  },
  watch: {
    $route(route) {
      this.currentRouteName = route.name;
      this.setItems();
    },
  },
};
</script>

<style></style>
