import Vue from "vue";
import VueRouter from "vue-router";
import AppLayout from "@/components/layouts/app/AppLayout";
import AppLogin from "@/components/layouts/login/AppLogin";
import AppMap from "@/components/layouts/map/AppMap";
import { TokenService } from "@/services/storage.service";
Vue.use(VueRouter);

const EmptyParentComponent = {
  template: "<router-view></router-view>",
  meta: {
    empty: true,
  },
};

const routes = [
  {
    path: "",
    redirect: { name: "Map" },
  },
  {
    path: "*",
    redirect: { name: "Map" },
  },
  // {
  //   path: '*',
  //   redirect: { name: 'Exception404' }
  // },
  {
    path: "/",
    component: AppLogin,
    meta: {
      login: true,
    },
    children: [
      {
        name: "Login",
        path: "/login",
        component: () => import("../views/Auth/Login.vue"),
      },
      {
        name: "ResetPassword",
        path: "/resetpassword",
        component: () => import("../views/Auth/ResetPassword.vue"),
      },
    ],
  },
  {
    path: "/",
    component: AppLayout,
    meta: {
      isAdmin: true,
    },
    children: [
      {
        component: EmptyParentComponent,
        path: "/watersupply",
        children: [
          {
            name: "WaterSupply",
            path: "supply",
            component: () => import("../views/WaterSupply/WaterSupply.vue"),
          },
          {
            name: "AddWaterSupply",
            path: "add",
            component: () => import("../views/WaterSupply/AddWaterSupply.vue"),
          },
        ],
      },
      {
        path: "/transfersupply",
        name: "TransferSupply",
        component: () => import("../views/TransferSupply/TransferSupply.vue"),
      },
      {
        component: EmptyParentComponent,
        path: "/usermanagement",
        children: [
          {
            path: "",
            name: "UserManagement",
            component: () => import("../views/UserManagement/Users.vue"),
          },
          {
            path: ":mode",
            name: "AddUser",
            component: () => import("../views/UserManagement/AddUser.vue"),
          },
          {
            path: ":mode/:userId",
            name: "EditUser",
            component: () => import("../views/UserManagement/AddUser.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/map",
    component: AppMap,
    children: [
      {
        path: "",
        name: "Map",
        component: () => import("@/views/Map/MapSearch.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach(async (to, from, next) => {
  let changeNext;
  const haveToken = !!TokenService.getToken();

  if (to.matched.some((record) => record.meta.login) && haveToken) {
    changeNext = "WaterSupply";
  } else if (to.matched.some((record) => record.meta.isAdmin) && !haveToken) {
    changeNext = "Login";
  }
  if (changeNext) {
    if (from.name !== changeNext) {
      changeNext = { name: changeNext };
    }
  }

  return next(changeNext);
});
router.afterEach(async (to, from, next) => {});
export default router;
