import httpClient from "../httpClient";
const END_POINT = "Filter";

class FilterService {
  async getListProvince() {
    const config = {
      method: "get",
      url: `${END_POINT}/FilterProvince`,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
  }
  async getListAmphor(provinceId = null) {
    const config = {
      method: "get",
      url: `${END_POINT}/FilterAmphor`,
      params: {
        provinceID: provinceId,
      },
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
  }
  async getListTambol(amphorId = null) {
    const config = {
      method: "get",
      url: `${END_POINT}/FilterTambol`,
      params: {
        amphorID: amphorId,
      },
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
  }
  async getListVillage(tambolId = null) {
    const config = {
      method: "get",
      url: `${END_POINT}/FilterVillage`,
      params: {
        tambolID: tambolId,
      },
    };

    const response = await httpClient(config);
    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
  }
  async getListZone() {
    const config = {
      method: "get",
      url: `${END_POINT}/FilterZone`,
    };

    const response = await httpClient(config);
    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
  }

  async getFilterSourceType() {
    const config = {
      method: "get",
      url: `${END_POINT}/FilterSourceType`,
    };

    const response = await httpClient(config);
    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
  }

  async getFilterTransferType() {
    const config = {
      method: "get",
      url: `${END_POINT}/FilterTransferType`,
    };

    const response = await httpClient(config);
    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
  }

  async getFilterStatusType() {
    const config = {
      method: "get",
      url: `${END_POINT}/FilterStatusType`,
    };

    const response = await httpClient(config);
    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
  }
  async getFilterDepByProvince(provinceId = null) {
    const config = {
      method: "get",
      url: `${END_POINT}/FilterDepByProvince`,
      params: {
        provinceID: provinceId,
      },
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
  }

  async getFilterDepartment(isReginal = null) {
    const config = {
      method: "get",
      url: `${END_POINT}/FilterDepartment`,
      params: {
        isReginal: isReginal,
      },
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
  }
}

export default new FilterService();
