import httpClient from "../httpClient";
const END_POINT = "WaterSupply";

class WaterSupplyService {
  async searchWater(param) {
    const config = {
      method: "get",
      url: `${END_POINT}/SearchWater`,
      params: param,
    };

    const response = await httpClient(config);
    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
  }

  async ExportExcelWater(param) {
    const config = {
      method: "get",
      url: `${END_POINT}/ExportExcelWater`,
      params: param,
    };

    const response = await httpClient(config);
    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
  }

  async getWaterByID(id) {
    const param = {
      waterSupplyID: id,
    };
    const config = {
      method: "get",
      url: `${END_POINT}/GetWaterByID`,
      params: param,
    };

    const response = await httpClient(config);
    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
  }

  async InsertWater(param) {
    const config = {
      method: "post",
      url: `${END_POINT}/InsertWater`,
      data: param,
      headers: {
        "content-type": "application/json",
      },
    };

    const response = await httpClient(config);

    return response;
  }

  async editWater(param) {
    const config = {
      method: "post",
      url: `${END_POINT}/EditWater`,
      data: param,
      headers: {
        "content-type": "application/json",
      },
    };

    const response = await httpClient(config);

    return response;
  }

  async deletetWater(id) {
    const param = {
      PWAProjectId: id,
    };
    const config = {
      method: "post",
      url: `${END_POINT}/DeleteWater`,
      data: param,
    };

    const response = await httpClient(config);

    return response;
  }
}

export default new WaterSupplyService();
