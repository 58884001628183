<template>
  <div id="AppMap" class="app-screen-large">
    <map-header></map-header>

    <main class="content-wrapper-map">
      <router-view></router-view>
    </main>
  </div>
</template>

<script>
import MapHeader from "./map-header/MapHeader.vue";

export default {
  name: "app-map",
  components: {
    "map-header": MapHeader,
  },
};
</script>

<style lang="scss">
#AppMap {
  height: 100vh;
}
</style>
