<template>
  <div id="AppLayout" class="app-screen-large">
    <app-header></app-header>

    <app-sidebar></app-sidebar>

    <main class="content-wrapper">
      <router-view></router-view>
    </main>
  </div>
</template>

<script>
import AppHeader from "./app-header/AppHeader.vue";
import AppSidebar from "./app-sidebar/AppSidebar.vue";

export default {
  name: "app-layout",
  components: { AppHeader, AppSidebar },
};
</script>

<style></style>
