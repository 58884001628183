import store from "@/store/index";

const getProfile = () => {
  const profile = store.state.account?.profile || {};
  return profile;
};

//สามารถใช้ในส่วนของ js
export default {
  getProfile,
};
