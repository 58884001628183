import httpClient from "../httpClient";
const END_POINT = "Map";

class MapService {
  async searchMap(param) {
    const config = {
      method: "get",
      url: `${END_POINT}/SearchMap`,
      params: param,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
  }
}

export default new MapService();
