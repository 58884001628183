import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import account from "./account.module";
import { menus } from "./menus.module.js";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      key: "dwrgisaccount",
      paths: ["account"],
    }),
  ],
  state: {},
  mutations: {},
  actions: {},
  modules: {
    menus,
    account,
  },
});
