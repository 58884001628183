import AppTitleContent from './cores/AppTitleContent'

const ComponentPlugin = {
  install(Vue, options) {
    [AppTitleContent].forEach(component => {
      Vue.component(component.name, component)
    });
  }
}

export default ComponentPlugin
