<template>
  <div class="app-sidebar">
    <div class="main-sidebar">
      <nav>
        <ul>
          <li
            class="li-menu cursor-pointer"
            v-for="menu in menus"
            :key="`menu-${menu.menuId}`"
            :class="{
              'active-menu': menu.active,
            }"
            @click="setMenuActive(menu)"
          >
            <div class="pl-2 py-3">
              <router-link
                :to="{ name: menu.to }"
                class="d-flex align-items-center"
              >
                <iconify :icon="`${menu.iconClass}`" class="fontsize-h2" />
                {{ menu.title }}
              </router-link>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: "app-sidebar",
  data() {
    return {
      menus: [],
    };
  },
  created() {
    this.menus = this.$store.state.menus.menus;
    const menu = this.menus.filter((f) => {
      return f.to === this.$route.name;
    })[0];
    this.setMenuActive(menu);
  },
  methods: {
    setMenuActive(menu) {
      this.menus.map((f) => {
        if (menu?.menuId !== f.menuId) {
          f.active = false;
        } else {
          f.active = true;
          this.$router.push({ name: menu.to }).catch(() => {});
        }

        return { ...f };
      });
    },
  },
};
</script>

<style></style>
