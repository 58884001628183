import httpClient from "../httpClient";
import { TokenService } from "../storage.service";

const END_POINT = "Auth";

class AccountService {
  async login(user) {
    const params = {
      userName: user.username,
      passWord: user.password,
    };

    const config = {
      method: "post",
      url: `${END_POINT}/Login`,
      data: params,
      headers: {
        "content-type": "application/json",
      },
    };

    const response = await httpClient(config);
    if (response && response?.resData) {
      const { accessToken, refreshToken } = response.resData.data;
      const { status } = response.resData;

      if (status && accessToken && refreshToken) {
        TokenService.saveToken(accessToken);
        TokenService.saveRefreshToken(refreshToken);

        return { status: true, accessToken: accessToken, refreshToken };
      } else {
        return { status: false };
      }
    } else {
      return { status: false };
    }
  }

  async logout() {
    const params = {
      RefreshToken: TokenService.getRefreshToken(),
    };

    const config = {
      method: "post",
      url: `${END_POINT}/Logout`,
      data: params,
      headers: {
        "content-type": "application/json",
      },
    };

    const response = await httpClient(config);

    if (response && response?.resData && response?.resData?.status) {
      TokenService.removeToken();
      TokenService.removeRefreshToken();
    } else {
      TokenService.removeToken();
      TokenService.removeRefreshToken();
    }
    return true;
  }

  async refreshToken() {
    const config = {
      method: "post",
      url: `${END_POINT}/RefreshToken`,
      data: {
        token: TokenService.getRefreshToken(),
      },
      headers: {
        Authorization: null,
      },
    };

    const response = await httpClient(config);

    if (response && response?.resData) {
      const { token, refreshToken } = response.resData;

      if (token && refreshToken) {
        TokenService.saveToken(token);
        TokenService.saveRefreshToken(refreshToken);
      } else {
        return null;
      }

      return { accessToken: token, refreshToken };
    } else {
      return null;
    }
  }
}

export default new AccountService();
