import httpClient from "../httpClient";
const END_POINT = "TransferSupply";

class TransferSupplyService {
  async searchTransfer(param) {
    const config = {
      method: "get",
      url: `${END_POINT}/SearchTransfer`,
      params: param,
    };

    const response = await httpClient(config);
    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
  }
  async getTransferByID(ID) {
    let param = {
      transferSupplyID: ID,
    };
    const config = {
      method: "get",
      url: `${END_POINT}/GetTransferByID`,
      params: param,
    };

    const response = await httpClient(config);
    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
  }
  async insertTransfer(param) {
    const config = {
      method: "post",
      url: `${END_POINT}/InsertTransfer`,
      data: param,
      headers: {
        "content-type": "application/json",
      },
    };
    const response = await httpClient(config);
    return response;
  }
  async editTransfer(param) {
    const config = {
      method: "post",
      url: `${END_POINT}/EditTransfer`,
      data: param,
      headers: {
        "content-type": "application/json",
      },
    };
    const response = await httpClient(config);
    return response;
  }
  async deleteTransfer(param) {
    const config = {
      method: "post",
      url: `${END_POINT}/DeleteTransfer`,
      data: param,
      headers: {
        "content-type": "application/json",
      },
    };
    const response = await httpClient(config);
    return response;
  }
  async exportExcelTransfer(param) {
    const config = {
      method: "get",
      url: `${END_POINT}/ExportExcelTransfer`,
      params: param,
      headers: {
        "content-type": "application/json",
      },
    };
    const response = await httpClient(config);
    return response;
  }
}

export default new TransferSupplyService();
