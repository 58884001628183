<template>
  <div class="app-header">
    <div class="main-header d-flex align-items-center px-3">
      <img
        class="header-logo-img"
        src="/images/logo.png"
        style="height: 50px; margin-right: 5px"
      />
      <div class="flex-fill">
        ระบบสารสนเทศภูมิศาสตร์ระบบประปาหมู่บ้าน กรมทรัพยากรน้ำ
      </div>
      <div>
        <b-navbar-nav class="mr-2 nav-user">
          <b-nav-item-dropdown right class="nav-dropdown-user">
            <template #button-content>
              <span
                class="font-darkgray ml-3 mr-2 text-white text-right"
                style="display: inline-block; min-width: 100px"
                >{{ usernameLogin }}</span
              >
              <b-avatar
                :src="userImage"
                class="mr-2"
                onerror="javascript:this.src='/images/default-profile.png'"
              ></b-avatar>
            </template>
            <b-dropdown-item href="#" @click="getLogout"
              ><font-awesome-icon
                class="font-reddanger"
                :icon="['fas', 'power-off']"
              />
              ออกจากระบบ</b-dropdown-item
            >
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "app-header",
  data() {
    return {
      usernameLogin: "username login",
      userImage: null,
    };
  },
  async created() {
    this.usernameLogin = this.getLoginName();
  },
  methods: {
    getLogout() {
      this.$store.dispatch("account/logout").then((res) => {
        this.$router.push({ name: "Login" });
      });
    },
    getLoginName() {
      const profile = this.$utils.getProfile();

      if (profile && profile.firstName && profile.lastName) {
        return profile.firstName + " " + profile.lastName;
      } else if (profile.firstName || profile.lastName) {
        return profile.firstName || "" + profile.lastName || "";
      }

      return "ไม่มีชื่อผู้ใช้งาน";
    },
  },
};
</script>

<style lang="scss"></style>
