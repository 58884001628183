import AccountService from "@/services/api/account.service";
import { TokenService } from "@/services/storage.service";
import { UserService } from "@/services/main.service";

const user = {
  accessToken: null,
  refreshToken: null,
};

const state = {
  user: { ...user },
  profile: {},
};

const actions = {
  login({ commit }, data) {
    return AccountService.login(data).then(
      (res) => {
        if (res.status) {
          const user = {
            accessToken: res.accessToken,
            refreshToken: res.refreshToken,
          };
          commit("loginSuccess", user);
          return Promise.resolve(res);
        } else {
          commit("loginFailure");
          return Promise.reject(res);
        }
      },
      (error) => {
        commit("loginFailure");
        return Promise.reject(error);
      }
    );
  },
  logout({ commit }) {
    return AccountService.logout().then(
      (res) => {
        commit("logoutSuccess");
        return Promise.resolve(res);
      },
      (error) => {
        commit("logoutFailure");
        return Promise.reject(error);
      }
    );
  },
  refresh({ commit }) {
    return AccountService.refreshToken().then(
      (res) => {
        commit("loginSuccess", res);
        return Promise.resolve(res);
      },
      (error) => {
        commit("loginFailure");
        return Promise.reject(error);
      }
    );
  },
  getprofile({ commit }) {
    return UserService.getProfile().then(
      (res) => {
        commit("setProfile", res);
        return Promise.resolve(res);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },
};

const mutations = {
  loginSuccess(state, user) {
    state.user = user;
  },
  loginFailure(state) {
    state.user = {};
    state.profile = {};
  },
  logoutSuccess(state) {
    state.user = {};
    state.profile = {};
  },
  logoutFailure(state, user) {
    state.user = {};
    state.profile = {};
  },
  setProfile(state, profile) {
    state.profile = profile;
  },
  saveLocalToken(state) {
    if (state.user && state.user.accessToken && state.user.refreshToken) {
      TokenService.saveToken(state.user.accessToken);
      TokenService.saveRefreshToken(state.user.refreshToken);
    } else {
      TokenService.removeToken();
      TokenService.removeRefreshToken();
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
