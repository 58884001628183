export const interceptorResponse = async (response) => {
  const customRes = {
    data: response.data.data,
    resStatusCode: response.status,
    resStatusText: response.statusText,
    resData: response.data,
  };

  return customRes;
};
